<script setup lang="ts">
import { AppLogoSmall } from '#components'
import { ChevronDownIcon, ChevronRightIcon, CloseIcon, MenuIcon, SearchIcon, TeacherIcon } from '@cfgtech/icons'
import { CfgButton, CfgDropdown, CfgTypography, CfgTypographySizes } from '@cfgtech/ui'
import {
  Dialog as DialogRoot,
  TransitionRoot,
} from '@headlessui/vue'
import { ROUTES } from '~/constants/routes'

// composables
const { t } = useI18n()

const { handleLogin, handleCTA } = useCta()

const isMobileMenuOpen = ref(false)

// This needs to be implemented due to collision of the close event on the DialogRoot and click event on menu button
function openMobileMenu() {
  isMobileMenuOpen.value = true
}

function closeMobileMenu() {
  setTimeout(() => {
    isMobileMenuOpen.value = false
  }, 50)
}

type NavigationItem = {
  label: string
  path: string
} & (
  | {
    description?: string
    icon?: Component
  }
  | {
    children: NavigationItem[]
  }
)

// computed
const navigationItems = computed<NavigationItem[]>(() => [
  {
    label: t('navigation.pages.projects.title'),
    path: ROUTES.PROJECTS,
  },
  {
    label: t('navigation.pages.about.title'),
    path: ROUTES.ABOUT,
    children: [
      {
        label: t('navigation.pages.about.title'),
        description: t('navigation.pages.about.description'),
        path: ROUTES.ABOUT,
        icon: AppLogoSmall,
      },
      {
        label: t('navigation.pages.howItWorks.title'),
        description: t('navigation.pages.howItWorks.description'),
        path: ROUTES.HOW_IT_WORKS,
        icon: TeacherIcon,
      },
      {
        label: t('navigation.pages.scoring.title'),
        description: t('navigation.pages.scoring.description'),
        path: ROUTES.SCORING,
        icon: SearchIcon,
      },
    ],
  },
  {
    label: t('navigation.pages.contact.title'),
    path: ROUTES.CONTACT,
  },
  {
    label: t('navigation.pages.forIssuers.title'),
    path: ROUTES.FOR_ISSUERS,
  },
])

const navigationItemsMobile = computed<NavigationItem[]>(() => navigationItems.value.flatMap(item => 'children' in item ? item.children : item))

const sentinelRef = useTemplateRef('sentinelRef')
const isStuck = ref(false)

useIntersectionObserver(
  sentinelRef,
  ([entry]) => {
    isStuck.value = !entry.isIntersecting
  },
  {
    threshold: 0,
  },
)
</script>

<template>
  <div ref="sentinelRef" class="h-px" />

  <div
    class="sticky top-0 z-sticky w-full py-2 transition duration-300 lg:px-0 lg:py-5"
    :class="{ 'bg-white/85 shadow-card backdrop-blur-[37px]': isStuck }"
  >
    <header class="flex h-10 flex-row items-center" data-allow-mismatch>
      <!-- Shared for desktop and mobile -->
      <div class="container grid size-full grid-cols-4 gap-x-5 max-lg:px-5 lg:grid-cols-12" data-allow-mismatch>
        <CommonLogo class="col-span-2 flex h-full items-center" />

        <nav class="col-span-8 max-lg:hidden">
          <ul class="flex size-full items-center justify-center gap-x-14 max-[1180px]:gap-x-6">
            <li v-for="item in navigationItems" :key="item.label">
              <NuxtLink
                v-if="!('children' in item)"
                v-ripple
                active-class="text-brand"
                class="flex gap-x-2 whitespace-nowrap hover:text-brand"
                :to="item?.path"
              >
                <CfgTypography class="!font-highlighted">
                  {{ item.label }}
                </CfgTypography>
              </NuxtLink>

              <CfgDropdown
                v-else
                class="p-2 [&_button]:border-0"

                max-width="590px"
                origin-x="right"
                overlay
              >
                <template #trigger>
                  <CfgTypography
                    class="flex items-center justify-center gap-2 !font-highlighted"
                    :class="$route.path.includes(item.path) ? 'text-brand' : ''"
                  >
                    {{ item.label }}
                    <ChevronDownIcon class="text-[24px]" />
                  </CfgTypography>
                </template>

                <template #default="{ close }">
                  <div class="grid grid-cols-2 gap-x-8 gap-y-6 p-7">
                    <template
                      v-for="(child, index) in item.children"
                      :key="index"
                    >
                      <NuxtLink
                        v-if="!('children' in child)"
                        active-class="is-active"
                        class="group"
                        :to="child.path"
                        @click="close"
                      >
                        <div class="flex gap-5">
                          <div class="flex size-11 shrink-0 items-center justify-center rounded-xl bg-brand-light">
                            <component :is="child.icon" class="text-[24px] text-black" />
                          </div>

                          <div class="flex w-48 flex-col items-start">
                            <CfgTypography class="!font-semibold underline group-[.is-active]:text-brand">
                              {{ child.label }}
                            </CfgTypography>

                            <CfgTypography class="text-grey" :size="CfgTypographySizes.sm">
                              {{ child.description }}
                            </CfgTypography>
                          </div>
                        </div>
                      </NuxtLink>
                      <!-- NOTICE: No support for deep nested children yet -->
                    </template>
                  </div>
                </template>
              </CfgDropdown>
            </li>
          </ul>
        </nav>

        <div class="col-span-2 ml-auto lg:hidden">
          <button v-ripple @click="openMobileMenu">
            <div class="flex flex-col items-center justify-center">
              <MenuIcon class="text-[24px]" />

              <CfgTypography class="!font-medium" :size="CfgTypographySizes.xs">
                Menu
              </CfgTypography>
            </div>
          </button>
        </div>

        <div class="col-span-2 flex items-center justify-end gap-6 max-lg:hidden">
          <CfgButton
            class="shrink-0 !font-highlighted"
            small
            text
            variant="orange"
            @click="handleLogin"
          >
            {{ t('navigation.actions.login') }}
          </CfgButton>

          <CfgButton
            class="rounded-full px-3 py-0.5 !font-highlighted"
            small
            variant="orange"
            @click="handleCTA"
          >
            {{ t('navigation.actions.cta') }}
          </CfgButton>
        </div>
      </div>

      <!-- Mobile -->
      <div class="ml-auto lg:hidden">
        <TransitionRoot as="template" :show="isMobileMenuOpen">
          <DialogRoot
            as="div"
            class="relative"
            :open="isMobileMenuOpen"
            @close="closeMobileMenu"
          >
            <div class="fixed inset-0 z-sticky h-full overflow-y-auto bg-white">
              <nav class="flex h-full flex-col">
                <div class="grid grid-cols-3 items-center border-b border-grey-50 px-5 py-2">
                  <AppLogoSmall class="text-[28px]" />

                  <CfgTypography
                    class="text-center !font-highlighted"
                    :size="CfgTypographySizes.h5"
                  >
                    {{ t('navigation.title') }}
                  </CfgTypography>

                  <button
                    v-ripple
                    class="ml-auto flex size-10 items-center justify-center"
                    @click="closeMobileMenu"
                  >
                    <CloseIcon class="text-[20px]" />
                  </button>
                </div>

                <ul class="divide-y divide-grey-50 px-5">
                  <li
                    v-for="item in navigationItemsMobile"
                    :key="item.label"
                    class="py-3"
                  >
                    <CfgTypography :size="CfgTypographySizes.md">
                      <NuxtLink
                        v-if="'path' in item"
                        active-class="text-brand"
                        class="whitespace-nowrap font-semibold"
                        :to="item.path"
                      >
                        {{ item.label }}
                      </NuxtLink>
                    </CfgTypography>
                  </li>
                </ul>

                <div class="mt-auto flex flex-col gap-6 p-5 pb-8">
                  <CfgButton
                    class="rounded-full px-3 py-0.5 !font-highlighted"
                    small
                    variant="orange"
                    @click="handleCTA"
                  >
                    {{ t('navigation.actions.cta') }}
                    <template #icon>
                      <ChevronRightIcon class="text-[24px]" />
                    </template>
                  </CfgButton>

                  <CfgButton
                    class="!font-highlighted"
                    small
                    text
                    variant="orange"
                    @click="handleLogin"
                  >
                    {{ t('navigation.actions.login') }}
                  </CfgButton>
                </div>
              </nav>
            </div>
          </DialogRoot>
        </TransitionRoot>
      </div>
    </header>
  </div>
</template>
